import React from 'react';
import ReactDOM from 'react-dom/client';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './services/queryClient';

import './index.css';

import Home from './pages/Home';
import About from './pages/About';
import Faq from './pages/Faq';
import Search from './pages/Search';
import Listing from './pages/Listings/Listing';
import Policy from './pages/Policy';
import Terms from './pages/Terms';
import Properties from './pages/Properties';
import PropertiesPreview from './pages/PropertiesPreview';
import ListingPreview from './pages/Listings/ListingPreview';
import Error from './pages/Error';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  { path: '/', element: <Home /> },
  { path: '/about', element: <About /> },
  { path: '/terms', element: <Terms /> },
  { path: '/policy', element: <Policy /> },
  { path: '/search', element: <Search /> },
  { path: '/faq', element: <Faq /> },
  { path: '/properties', element: <Properties /> },
  { path: '/properties/:id', element: <PropertiesPreview /> },
  { path: '/listings', element: <Listing /> },
  { path: '/listings/:id', element: <ListingPreview /> },
  { path: '*', element: <Error /> },
]);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },

          success: {
            duration: 3000,
          },
        }}
      />
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);
