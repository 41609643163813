import React from 'react';
import WebsiteLayout from '../components/layouts/WebsiteLayout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { AiOutlineHeart, AiOutlineShareAlt } from 'react-icons/ai';
import { LuArmchair, LuBed, LuTag } from 'react-icons/lu';
import LoadingComponent from '../components/primary/LoadingComponent';
import { useFetchListings } from '../hooks/useFetchListings';
import { NumericFormat } from 'react-number-format';
import { BsTelephoneFill } from 'react-icons/bs';
import { RiWhatsappFill } from 'react-icons/ri';
import BiggerSliderComponent from '../components/primary/BiggerSliderComponent';

type Props = {};

const map = require('../assets/search/map.png');

const PropertiesPreview = (props: Props) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const { id } = useParams();
  const { data, isFetching } = useFetchListings(id);

  return (
    <WebsiteLayout title="Property Preview" link="https://rentaly.rent/preview">
      <div className="container mx-auto md:px-0 px-5">
        <div className="mt-[30px]">
          <span
            onClick={() => goBack()}
            className="my-8 flex gap-3 items-center cursor-pointer"
          >
            <BiArrowBack />
            Back
          </span>
        </div>

        {isFetching && <LoadingComponent />}

        {data?.data && !isFetching && (
          <>
            <h3 className="text-3xl capitalize font-bold">
              {data?.data?.title}
            </h3>
            <div className="md:flex justify-between items-center mt-3">
              <h3 className="text-2xl font-medium">{data?.data?.address}</h3>
              <div className="md:flex gap-3 md:my-0 my-4 flex justify-between">
                <button className="flex gap-2 items-center cursor-pointer text-sm">
                  <AiOutlineHeart />
                  <span>Save</span>
                </button>
                <button className="flex gap-2 items-center cursor-pointer text-sm">
                  <AiOutlineShareAlt />
                  <span>Share</span>
                </button>
              </div>
            </div>
            <BiggerSliderComponent data={data?.data?.images} />
            <div
              className="mx-auto w-[90%] bg-blue rounded-md z-40 md:grid md:grid-cols-4 text-white p-[40px] gap-20"
              style={{ zIndex: 40 }}
            >
              <div className="flex items-center font-light md:mb-0 mb-4">
                <LuTag />
                <div className="ml-2 ">For</div>
                <span className="ml-2 capitalize">{data?.data?.tag}</span>
              </div>
              <div className="flex items-center font-light md:mb-0 mb-4">
                <LuBed />
                <div className="ml-2 ">Bathroom -</div>
                <span className="ml-2">{3}</span>
              </div>
              <div className="flex items-center font-light md:mb-0 mb-4">
                <LuArmchair />
                <div className="ml-2 ">Living Room -</div>
                <span className="ml-2">{1}</span>
              </div>
              <div className="flex items-center font-bold">
                <NumericFormat
                  value={data?.data?.askingPrice}
                  prefix="&#8358;"
                  thousandSeparator=","
                  displayType="text"
                />
              </div>
            </div>
            <div className="mx-auto w-[90%] mt-20">
              <div className="flex justify-between">
                <h3 className="text-2xl text-black font-bold">Description</h3>
              </div>
              {data?.data?.description ? (
                <p className="text-gray">{data?.data?.description}</p>
              ) : (
                '--'
              )}
              <div className="flex gap-3">
                <Link
                  to={`tel:${data?.data?.principalPhone}`}
                  className="text-center flex gap-3 items-center bg-blue text-white py-3 px-3 rounded-md cursor-pointer my-3 text-base font-bold"
                >
                  <BsTelephoneFill className="text-xl" />
                  Call Phone
                </Link>

                <Link
                  to={`http://wa.me/${data?.data?.principalWhatsapp}?text=I'm%20interested%20in%20your%20${data?.data?.title}%20property%20for%20${data?.data?.tag}`}
                  target="_blank"
                  className="flex gap-2 border-[2px] border-blue text-center items-center bg-white text-blue py-3 px-3 rounded-md cursor-pointer my-3 text-base font-bold"
                >
                  <RiWhatsappFill className="text-[#4caf50]" />
                  Chat on WhatsApp
                </Link>
              </div>
              <div className="md:flex mt-12">
                <div className="md:w-6/12 md:mt-0 mt-10"></div>
              </div>
              <h3 className="text-black font-bold text-2xl mt-12">
                Directions
              </h3>
              <img src={map} alt="" className="w-full my-3" />
            </div>
          </>
        )}
      </div>
    </WebsiteLayout>
  );
};

export default PropertiesPreview;
