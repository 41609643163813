import axios from 'axios';
import toast from 'react-hot-toast';
import env from 'react-dotenv';

export const axiosInstance = axios.create({
  baseURL: env.BASE_URL,
});

const stringifyAccessToken = localStorage.getItem('@accessToken');
const token = JSON.parse(stringifyAccessToken);

axiosInstance.interceptors.request.use(
  async (config) => {
    if (token && config.headers) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  async (error) => {
    toast.error(error);
    return Promise.reject(error);
  }
);
