export const objectToQueryString = (obj) => {
  const keys = Object.keys(obj);
  const keyValuePairs = keys
    .filter((key) => {
      const value = obj[key];
      return (
        value !== '' &&
        value !== 0 &&
        !(Array.isArray(value) && value.length === 0)
      );
    })
    .map((key) => {
      const value = obj[key];
      const encodedKey = encodeURIComponent(key);

      if (Array.isArray(value)) {
        const encodedValues = value
          .map((item) => encodeURIComponent(item))
          .join(',');
        return `${encodedKey}=${encodedValues}`;
      }

      return `${encodedKey}=${encodeURIComponent(value)}`;
    });

  return keyValuePairs.join('&');
};
