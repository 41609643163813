import WebsiteLayout from '../../components/layouts/WebsiteLayout';
import { Fade } from 'react-awesome-reveal';
import 'react-slideshow-image/dist/styles.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LuArmchair, LuBath, LuBed } from 'react-icons/lu';
import { AiOutlineHeart, AiOutlineShareAlt } from 'react-icons/ai';
import { BiArrowBack } from 'react-icons/bi';
import { useGetOneListingQuery } from '../../hooks/useGetOneListingQuery';
import { NumericFormat } from 'react-number-format';
import SliderComponent from '../../components/primary/SliderComponent';
import LoadingComponent from '../../components/primary/LoadingComponent';
import Moment from 'react-moment';
import { IoLocationOutline } from 'react-icons/io5';
import ListingChecklist from './ListingChecklist';
import PropertyPreference from './PropertyPreference';
// const map = require('../../assets/search/map.png');

type Props = {};

const ListingPreview = (props: Props) => {
  const { id } = useParams();

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const no360 = require('../../assets/no360.png');

  const { data, isFetching } = useGetOneListingQuery(id);

  return (
    <WebsiteLayout title="Listing Preview" link="https://rentaly.rent/listing">
      <Fade>
        <div className="container mx-auto md:px-0 px-5">
          <div className="mt-[30px]">
            <span
              onClick={() => goBack()}
              className="my-8 flex gap-3 items-center cursor-pointer"
            >
              <BiArrowBack />
              Back
            </span>
          </div>
          {isFetching && <LoadingComponent />}

          {data?.data && !isFetching && (
            <div>
              <div className="md:flex block gap-4">
                <div className="md:w-6/12 w-full">
                  <SliderComponent data={data?.data?.images} />
                </div>
                <div className="md:w-6/12 w-full">
                  <div className="flex justify-between">
                    <h3 className="text-2xl text-black font-bold">
                      Description
                    </h3>
                  </div>
                  <p className="text-gray mb-6">
                    Listed on:{' '}
                    <Moment format="DD MMM YYYY" interval={0}>
                      {data?.data?.createdAt}
                    </Moment>
                  </p>
                  <p className="text-gray mb-6">{data?.data?.description}</p>
                  <div className="md:flex gap-3">
                    <button className="flex gap-2 items-center cursor-pointer text-sm">
                      <AiOutlineHeart />
                      <span>Save</span>
                    </button>
                    <button className="flex gap-2 items-center cursor-pointer text-sm">
                      <AiOutlineShareAlt />
                      <span>Share</span>
                    </button>
                  </div>
                  <Link
                    to={`https://staging-app.rentaly.rent/tenant/dashboard/applications/make-applications?id=${data?.data?.id}`}
                    className="text-center text-base inline-block bg-blue text-white py-3 px-3 rounded-md cursor-pointer my-3"
                  >
                    Submit Application
                  </Link>
                </div>
              </div>
              <div className="md:flex block gap-4 py-20">
                <div className="md:w-6/12 w-full my-3">
                  <h2 className="font-bold">Property Details</h2>
                  <div className="mt-4 bg-[#4d4dc42f] border-[2px] border-blue rounded-md p-4">
                    <b>Property Address</b>
                    <div className="mt-3"></div>
                    <div className="mb-2 flex gap-2">
                      <IoLocationOutline className=" text-[20px]" />
                      <span className="">
                        {data?.data?.city + ', ' + data?.data?.state}
                      </span>
                    </div>
                    <div className="flex gap-4 border-b-[1px] border-blue pb-3">
                      <div className="w-6/12">
                        <b>Property Details</b>
                        <div className="flex mt-2 items-center font-light text-black1">
                          <LuArmchair />
                          <div className="flex-1 ml-2">
                            Living Room - {data?.data?.noOfLivingRoom}
                          </div>
                        </div>
                        <div className="flex mt-2 items-center font-light text-black1">
                          <LuBed />
                          <div className="flex-1 ml-2">
                            Bedrooms - {data?.data?.noOfBedrooms}
                          </div>
                        </div>
                        <div className="flex mt-2 items-center font-light text-black1">
                          <LuBath />
                          <div className="flex-1 ml-2">
                            Bathrooms - {data?.data?.noOfBathrooms}
                          </div>
                        </div>
                      </div>
                      <div className="w-6/12">
                        <b>Summary</b>
                        <div className="mb-3">
                          Annual Rent -{' '}
                          <NumericFormat
                            value={data?.data?.annualRent}
                            prefix="&#8358;"
                            thousandSeparator=","
                            displayType="text"
                            className="font-bold"
                          />
                        </div>
                        <div className="mb-3">
                          Monthly Rent -{' '}
                          <NumericFormat
                            value={data?.data?.annualRent}
                            prefix="&#8358;"
                            thousandSeparator=","
                            displayType="text"
                            className="font-bold"
                          />
                        </div>
                        <div className="mb-3">
                          Minimum Deposit -{' '}
                          <NumericFormat
                            value={data?.data?.minimumDeposit}
                            prefix="&#8358;"
                            thousandSeparator=","
                            displayType="text"
                            className="font-bold"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="my-3">
                      Minimum Tenancy Length -{' '}
                      {data?.data?.minimumTenancyLength} months
                    </div>
                    <div className="my-3">
                      Maximim Number of Tenants - {data?.data?.maxNoOfTenants}{' '}
                      occupants
                    </div>
                  </div>
                </div>
                <div className="md:w-6/12 w-full my-3">
                  <h2 className="font-bold mb-3">360° View</h2>
                  <img src={no360} alt="360 view" className="mb-3" />
                  <h2 className="font-bold mb-3">Tenancy Preference</h2>
                  <PropertyPreference
                    familiesAllowed={data?.data?.familiesAllowed}
                    workingClass={data?.data?.workingClass}
                    studentAllowed={data?.data?.studentAllowed}
                    petsAllowed={data?.data?.petsAllowed}
                    smokersAllowed={data?.data?.smokersAllowed}
                  />

                  <h2 className="font-bold mt-3">Amenities</h2>
                  <ListingChecklist
                    waterHeater={data?.data?.waterHeater}
                    estateHousing={data?.data?.estateHousing}
                    internetService={data?.data?.internetService}
                    solarInverter={data?.data?.solarInverter}
                    sportsArea={data?.data?.sportsArea}
                    security={data?.data?.security}
                    wasteDisposalService={data?.data?.wasteDisposalService}
                    parkingSpace={data?.data?.parkingSpace}
                  />
                </div>
              </div>
              {/* <h3 className="text-3xl capitalize font-bold">
                {data?.data?.propertyType}
              </h3>
              <div className="md:flex justify-between items-center my-3">
                <h3 className="text-2xl font-medium">
                  {data?.data?.city + ', ' + data?.data?.state}
                </h3>
                <div className="md:flex gap-3">
                  <button className="flex gap-2 items-center cursor-pointer text-sm">
                    <AiOutlineHeart />
                    <span>Save</span>
                  </button>
                  <button className="flex gap-2 items-center cursor-pointer text-sm">
                    <AiOutlineShareAlt />
                    <span>Share</span>
                  </button>
                </div>
              </div> */}
              {/* <SliderComponent data={data?.data?.images} /> */}
              {/* <div className="mx-auto w-[90%] bg-blue rounded-md z-40 md:grid md:grid-cols-4 text-white p-[40px] gap-20 -mt-[30px]">
                <div className="flex items-center font-light">
                  <LuBed />
                  <div className="ml-2 ">Bedrooms -</div>
                  <span className="ml-2">{data?.data?.noOfBedrooms}</span>
                </div>
                <div className="flex items-center font-light">
                  <LuBath />
                  <div className="ml-2 ">Bathroom -</div>
                  <span className="ml-2">{data?.data?.noOfBathrooms}</span>
                </div>
                <div className="flex items-center font-light">
                  <LuArmchair />
                  <div className="ml-2 ">Living Room -</div>
                  <span className="ml-2">{data?.data?.noOfLivingRoom}</span>
                </div>
                <div className="flex items-center">
                  <span className="ml-2">
                    <NumericFormat
                      value={data?.data?.annualRent}
                      prefix="&#8358;"
                      thousandSeparator=","
                      displayType="text"
                    />
                    /year
                  </span>
                </div>
              </div>
              <div className="mx-auto w-[90%] mt-20">
                <div className="md:flex mt-12">
                  <div className="md:w-6/12">
                    <h3 className="text-black font-bold text-2xl">
                      Features & Amenities
                    </h3>
                    <ul className="list-disc ml-4 mt-3 text-gray">
                      {data?.data?.security && <li>Security</li>}
                      {data?.data?.sportsArea && <li>Sport Area</li>}
                      {data?.data?.solarInverter && <li>Solar Inverter</li>}
                      {data?.data?.internetService && <li>Internet Service</li>}
                      {data?.data?.estateHousing && <li>Estate Housing</li>}
                      {data?.data?.waterHeater && <li>Water Heater</li>}
                    </ul>
                  </div>
                  <div className="md:w-6/12 md:mt-0 mt-10"></div>
                </div>
                <h3 className="text-black font-bold text-2xl mt-12">
                  Directions
                </h3>
                <img src={map} alt="" className="w-full my-3" />
              </div> */}
            </div>
          )}
        </div>
      </Fade>
    </WebsiteLayout>
  );
};

export default ListingPreview;
