const diligence = require('../assets/diligence.png');
const accountability = require('../assets/accountability.png');
const profitability = require('../assets/profitability.png');
const sustainability = require('../assets/sustainability.png');
const clients = require('../assets/clients.png');
const priductivity = require('../assets/priductivity.png');
const walletIcon = require('../assets/walletIcon.png');
const chargesicon = require('../assets/chargesicon.png');
const handshakeicon = require('../assets/handshakeicon.png');
const homeicon = require('../assets/homeicon.png');

const property1 = require('../assets/properties/01.png');
const property2 = require('../assets/properties/02.png');
const property3 = require('../assets/properties/03.png');
const property4 = require('../assets/properties/04.png');

const person1 = require('../assets/people/trump.png');
const person2 = require('../assets/people/naruto.png');
const person3 = require('../assets/people/seyi.png');
const person4 = require('../assets/people/tom.png');

const search1 = require('../assets/search/house1.png');
const search2 = require('../assets/search/house2.png');
const search3 = require('../assets/search/house3.png');
const search4 = require('../assets/search/house4.png');

export const coreValues = [
  {
    id: 1,
    title: 'Transparency',
    icon: diligence,
    desc: 'Transparency is at the heart of what we do. We believe in providing clear and honest information, ensuring that both landlords and tenants can make informed decisions with confidence. Open communication is the foundation of trust in the Rentaly community.',
  },
  {
    id: 2,
    title: 'Empowerment',
    icon: accountability,
    desc: 'We empower landlords to take control of their properties and tenants to find their ideal homes. Through innovative tools and resources, we strive to give our community the confidence and autonomy to navigate the rental process with ease.',
  },
  {
    id: 3,
    title: 'Fairness',
    icon: profitability,
    desc: 'Fairness is a fundamental principle in our approach. We are committed to creating a level playing field for landlords and tenants, eliminating unnecessary obstacles, and promoting a rental experience that is fair, equitable, and beneficial for all.',
  },
  {
    id: 4,
    title: 'Innovation',
    icon: sustainability,
    desc: 'Embracing innovation is essential to our mission. We continually seek new ways to enhance the rental experience through technology and modern solutions. Our commitment to innovation ensures that Rentaly remains at the forefront of the evolving rental landscape.',
  },
  {
    id: 5,
    title: 'Community',
    icon: clients,
    desc: 'Community is the heartbeat of Rentaly. We foster connections between landlords and tenants, creating a supportive network where everyone feels valued. Building a sense of community enhances the overall rental experience and contributes to a positive and collaborative environment.',
  },
  {
    id: 6,
    title: 'Environmental Responsibility',
    icon: priductivity,
    desc: 'We understand the importance of environmental sustainability. In our commitment to responsible business practices, we aim to minimize our ecological footprint and encourage eco-friendly initiatives within the rental community.',
  },
];

export const team = [
  {
    id: 1,
    name: 'Jacob Akinloye',
    role: 'Co Founder',
  },
  {
    id: 2,
    name: 'Jacob Akinloye',
    role: 'Co Founder',
  },
  {
    id: 3,
    name: 'Jacob Akinloye',
    role: 'Product Manager',
  },
  {
    id: 4,
    name: 'Jacob Akinloye',
    role: 'Customer & Business Developement',
  },
  {
    id: 5,
    name: 'Jacob Akinloye',
    role: 'Head of Commercial Relations',
  },
  {
    id: 6,
    name: 'Jacob Akinloye',
    role: 'Legal Operations',
  },
  {
    id: 7,
    name: 'Jacob Akinloye',
    role: 'Full Stack Engineer',
  },
  {
    id: 8,
    name: 'Jacob Akinloye',
    role: 'Front-End Developer',
  },
  {
    id: 9,
    name: 'Jacob Akinloye',
    role: 'Front-End Developer',
  },
  {
    id: 10,
    name: 'Jacob Akinloye',
    role: 'Product Designer',
  },
  {
    id: 11,
    name: 'Jacob Akinloye',
    role: 'Content Manager',
  },
];

export const faq = [
  {
    id: 1,
    header: 'How DO I LIST MY PROPERTY?',
    body: 'Welcome to Rentaly, your trusted partner in transforming the home rental experience in Nigeria. At Rentaly, we are not just a platform; we are a movement that seeks to transform the way people experience home rentals in Nigeria.',
  },
  {
    id: 2,
    header: 'DOES RENTALY WORK OUTSIDE THE COUNTRY?',
    body: 'As of now, Rentaly is focused on connecting landlords and tenants within Nigeria. We are dedicated to serving the local market and providing a platform tailored to the unique needs of the Nigerian rental landscape.',
  },
  {
    id: 3,
    header: 'How much does the rentaly admin service cost?',
    body: "Rentaly's admin service is designed to be cost-effective. We believe in fair and transparent pricing. For detailed information on our admin service fees, please visit our [Pricing] page or contact our support team for personalized assistance.",
  },
  {
    id: 4,
    header:
      'What safety measures does Rentaly take to protect user information?',
    body: 'At Rentaly, the security and privacy of our users are top priorities. We employ industry-standard security measures to safeguard your information. Our platform uses encryption protocols, and we follow best practices to ensure the confidentiality and integrity of user data.',
  },
];

export const whyus = [
  {
    id: 1,
    icon: walletIcon,
    title: 'Direct Connections',
    body: 'Rentaly empowers you to connect directly with landlords, cutting out the middleman. This means faster communication, transparent dealings, and a more personalized renting experience.',
  },
  {
    id: 2,
    icon: chargesicon,
    title: 'Cost Savings',
    body: 'Forget about those hefty agent fees. With Rentaly, you save significantly on rental costs, allowing you to invest in what matters most – your new home or property.',
  },
  {
    id: 3,
    icon: handshakeicon,
    title: 'Extensive Listings',
    body: 'Explore a diverse range of home rentals tailored to your preferences.Our platform hosts an extensive selection of properties across Nigeria, ensuring you find the perfect match for your needs.',
  },
  {
    id: 4,
    icon: homeicon,
    title: 'Tenant Verification',
    body: "For landlords, Rentaly offers robust tenant verification tools, providing peace of mind. We prioritize your property's safety by facilitating background checks and ensuring trustworthy tenant placements.",
  },
];

export const newProperties = [
  {
    image: property1,
    id: 1,
    description:
      '1 Bedroom Apartment with Kitchen and Bathroom. Fully Furnished and Decorated',
    location: 'Admiralty Drive, Lekki Phase 1',
    price: '2,250,000',
    livingroom: 1,
    bedroom: 1,
    bathroom: 1,
  },
  {
    image: property2,
    id: 2,
    description:
      '5 Bedroom Apartment Fully Furnished and Decorated. Open Pool and Spaced',
    location: 'Victoria Garden City, Lekki',
    price: '2,250,000',
    livingroom: 1,
    bedroom: 7,
    bathroom: 8,
  },
  {
    image: property3,
    id: 3,
    description:
      '1 Bedroom Apartment with Kitchen and Bathroom. Fully Furnished and Decorated',
    location: 'Admiralty Drive, Lekki Phase 1',
    price: '2,250,000',
    livingroom: 1,
    bedroom: 3,
    bathroom: 4,
  },
  {
    image: property4,
    id: 4,
    description:
      '1 Bedroom Apartment with Kitchen and Bathroom. Fully Furnished and Decorated',
    location: 'New Yidi Estate, Asa-Dam, Ilorin.',
    price: '2,250,000',
    livingroom: 1,
    bedroom: 2,
    bathroom: 3,
  },
];

export const testimonials = [
  {
    id: 1,
    picture: person1,
    name: 'Donald Trump',
    testimonial:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.',
  },
  {
    id: 2,
    picture: person2,
    name: 'Uzumaki Naruto',
    testimonial:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.',
  },
  {
    id: 3,
    picture: person3,
    name: 'Lord Singleton',
    testimonial:
      'I found the perfect Single Bedroom with Rentaly, it has everything I need to live my single life to the fullest.',
  },
  {
    id: 4,
    picture: person4,
    name: 'Tom Brady',
    testimonial:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.',
  },
];

export const search = [
  {
    id: 1,
    image: search1,
    price: '850,000',
    title: '3 bedroom flat',
    desc: 'Lorem ipsum dolor sit amet consectetur. Mauris tempus luctus sit et ultricies curabitur vel. Placerat fames libero nibh enim nulla lacinia tellus pharetra non. Aliquam et dictumst tellus orci tincidunt magna mollis.',
    bedroom: 3,
    bathroom: 3,
    livingroom: 1,
    dateListed: '23rd oct. 2023',
    location: 'Oyo, Ibadan',
  },
  {
    id: 2,
    image: search2,
    price: '900,000',
    title: 'Oyo, Ibadan',
    desc: 'Lorem ipsum dolor sit amet consectetur. Mauris tempus luctus sit et ultricies curabitur vel. Placerat fames libero nibh enim nulla lacinia tellus pharetra non. Aliquam et dictumst tellus orci tincidunt magna mollis.',
    bedroom: 3,
    bathroom: 2,
    livingroom: 1,
    dateListed: '23rd oct. 2023',
    location: 'Oyo, Ibadan',
  },
  {
    id: 3,
    image: search3,
    price: '480,000',
    title: 'Oyo, Ibadan',
    desc: 'Lorem ipsum dolor sit amet consectetur. Mauris tempus luctus sit et ultricies curabitur vel. Placerat fames libero nibh enim nulla lacinia tellus pharetra non. Aliquam et dictumst tellus orci tincidunt magna mollis.',
    bedroom: 3,
    bathroom: 2,
    livingroom: 1,
    dateListed: '23rd oct. 2023',
    location: 'Oyo, Ibadan',
  },
  {
    id: 4,
    image: search4,
    price: '480,000',
    title: 'Oyo, Ibadan',
    desc: 'Lorem ipsum dolor sit amet consectetur. Mauris tempus luctus sit et ultricies curabitur vel. Placerat fames libero nibh enim nulla lacinia tellus pharetra non. Aliquam et dictumst tellus orci tincidunt magna mollis.',
    bedroom: 3,
    bathroom: 2,
    livingroom: 1,
    dateListed: '23rd oct. 2023',
    location: 'Oyo, Ibadan',
  },
  {
    id: 5,
    image: search4,
    price: '480,000',
    title: 'Oyo, Ibadan',
    desc: 'Lorem ipsum dolor sit amet consectetur. Mauris tempus luctus sit et ultricies curabitur vel. Placerat fames libero nibh enim nulla lacinia tellus pharetra non. Aliquam et dictumst tellus orci tincidunt magna mollis.',
    bedroom: 3,
    bathroom: 2,
    livingroom: 1,
    dateListed: '23rd oct. 2023',
    location: 'Oyo, Ibadan',
  },
  {
    id: 6,
    image: search4,
    price: '480,000',
    title: 'Oyo, Ibadan',
    desc: 'Lorem ipsum dolor sit amet consectetur. Mauris tempus luctus sit et ultricies curabitur vel. Placerat fames libero nibh enim nulla lacinia tellus pharetra non. Aliquam et dictumst tellus orci tincidunt magna mollis.',
    bedroom: 3,
    bathroom: 2,
    livingroom: 1,
    dateListed: '23rd oct. 2023',
    location: 'Oyo, Ibadan',
  },
  {
    id: 7,
    image: search4,
    price: '480,000',
    title: 'Oyo, Ibadan',
    desc: 'Lorem ipsum dolor sit amet consectetur. Mauris tempus luctus sit et ultricies curabitur vel. Placerat fames libero nibh enim nulla lacinia tellus pharetra non. Aliquam et dictumst tellus orci tincidunt magna mollis.',
    bedroom: 3,
    bathroom: 2,
    livingroom: 1,
    dateListed: '23rd oct. 2023',
    location: 'Oyo, Ibadan',
  },
];

export const amenities = [
  { id: 1, title: 'Water Heater' },
  { id: 2, title: 'Internet Service' },
  { id: 3, title: 'Sport Area' },
  { id: 4, title: 'Parkiing Space' },
  { id: 5, title: 'Backup Power' },
  { id: 6, title: 'Security' },
  { id: 7, title: 'Water Disposal' },
  { id: 8, title: 'Estate Housing' },
];

export const preference = [
  { id: 1, title: 'family', selected: true },
  { id: 2, title: 'Working Class', selected: true },
  { id: 3, title: 'Students', selected: false },
  { id: 4, title: 'Smokers', selected: false },
  { id: 5, title: 'Pets', selected: true },
];

export const furnishoptions = [
  {
    name: 'fully-furnished',
    label: 'Fully Furnished',
  },
  {
    name: 'semi-furnished',
    label: 'Semi Furnished',
  },
  {
    name: 'un-furnished',
    label: 'Un Furnished',
  },
];

export const propertytypeoptions = [
  {
    name: 'mansion',
    label: 'Mansion',
  },
  {
    name: 'flats',
    label: 'Flats',
  },
  {
    name: 'bungalow',
    label: 'Bungalow',
  },
  {
    name: 'self-contain',
    label: 'Self Contain',
  },
  {
    name: 'duplex',
    label: 'Duplex',
  },
  {
    name: 'others',
    label: 'Others',
  },
];
