import React from 'react';
import { BsTelephone } from 'react-icons/bs';
import { IoLocationOutline } from 'react-icons/io5';
import { RiWhatsappFill } from 'react-icons/ri';
import { NumericFormat } from 'react-number-format';
import { Link } from 'react-router-dom';

type Props = {
  id: string;
  askingPrice: string;
  tag: string;
  title: string;
  address: string;
  principalPhone: string;
  principalWhatsapp: string;
  images: string[];
};

const PropertiesCard = ({
  id,
  askingPrice,
  tag,
  title,
  address,
  principalPhone,
  principalWhatsapp,
  images,
}: Props) => {
  return (
    <div className="bg-white shadow-md rounded-md" key={id}>
      <div
        className="h-[200px] rounded-t-md relative"
        style={{
          backgroundImage: `${
            images[0] === ''
              ? '#a9a9a9'
              : `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url(${images[0]}`
          }`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute bottom-0 mb-4 ml-4 text-white">
          <NumericFormat
            value={askingPrice}
            prefix="&#8358;"
            thousandSeparator=","
            displayType="text"
            className="font-bold"
          />
          <div className="capitalize">{tag}</div>
        </div>
      </div>
      <div className="p-3">
        <h3 className="font-bold mb-1">{title}</h3>
        <div className="mb-2 flex gap-2">
          <IoLocationOutline className=" text-[20px]" />
          {address === '' ? (
            <span className="p-2 bg-[#c7c7c7] w-8/12"></span>
          ) : (
            address
          )}
        </div>
        <hr style={{ borderTop: '1px solid #ddd' }} />
        <Link
          to={`tel:${principalPhone}`}
          className="mb-2 flex gap-2 items-center mt-3"
        >
          <BsTelephone className=" text-xl" />
          {principalPhone === '' ? (
            <span className="p-2 bg-[#c7c7c7] w-8/12"></span>
          ) : (
            principalPhone
          )}
        </Link>
        <Link
          to={`http://wa.me/${principalWhatsapp}?text=I'm%20interested%20in%20your%20${title}%20property%20for%20${tag}`}
          target="_blank"
          className="mb-2 flex gap-2 items-center mt-3"
        >
          <RiWhatsappFill className="text-xl text-[#4caf50]" />
          <span>Chat on WhatsApp</span>
        </Link>
        <Link
          to={`/properties/${id}`}
          className="bg-blue p-2 w-full text-center block text-white mt-3 rounded-md cursor-pointer hover:opacity-80 transition ease-in-out duration-300 text-lg"
        >
          View Details
        </Link>
      </div>
    </div>
  );
};

export default PropertiesCard;
