import { useQuery } from 'react-query';
import { axiosInstance } from '../utils/axiosInstance';
import { apiConfig } from '../config/apiConfig';

export const useFetchListings = (id: string) => {
  return useQuery({
    queryKey: 'useGetOneListingsQuery',
    queryFn: () =>
      axiosInstance
        .get(`${apiConfig.PROPERTIES}/${id}`)
        .then((res: any) => res.data),
    onSuccess: () => {
      console.log('✅ GET ONE PROPERTY');
    },
    onError: (error: any) => {
      console.log('❌ GET ONE PROPERTY ==>', error.message);
    },
  });
};
