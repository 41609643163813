import React, { useState } from 'react';
import { AiOutlineSend } from 'react-icons/ai';
import { useSubscribeMutation } from '../../hooks/useSubscribeMutation';
import toast from 'react-hot-toast';

const footerImage = require('../../assets/footerImage.png');

const NewsLetter = () => {
  const [email, setEmail] = useState('');
  const { mutateAsync: subscribe } = useSubscribeMutation();

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleButtonClick = (e) => {
    if (isValidEmail(email)) {
      e.preventDefault();
      subscribe(email, {
        onSuccess: () => {
          setEmail('');
        },
      });
    } else {
      toast.error('Input a valid email');
    }
  };

  return (
    <div className="bg-black1 mt-[150px]">
      <div className="md:flex">
        <div className="md:w-6/12 w-full">
          <img src={footerImage} alt="" className="-mt-40" />
        </div>
        <div className="md:w-6/12 w-full py-24 text-white md:px-0 px-5">
          <h3 className="text-3xl font-bold">
            Get Our Monthly <br /> Newsletter
          </h3>
          <p className="mt-3 font-normal max-w-[550px]">
            Subscribe to our monthly newsletter to stay updated with the latest
            news, tips, and insights on home rentals in Nigeria. Be the first to
            know about exclusive offers, new listings, and industry trends.
            Simply enter your email address below to join our mailing list.
          </p>
          <div className="md:w-7/12 flex mt-3">
            <input
              type="text"
              className="p-3 bg-[#494949] flex-1"
              placeholder="Your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              className="bg-blue p-2 px-7"
              onClick={(e) => handleButtonClick(e)}
            >
              <AiOutlineSend />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
