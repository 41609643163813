import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { VscSettings } from 'react-icons/vsc';
import { BiSortAlt2 } from 'react-icons/bi';
import WebsiteLayout from '../components/layouts/WebsiteLayout';
import { useSearchQuery } from '../hooks/useSearchQuery';
import Sidebar from '../components/primary/Sidebar';
import LoadingComponent from '../components/primary/LoadingComponent';
import { objectToQueryString } from '../helpers/objectToQueryString';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import PropertiesSearchResult from '../components/primary/PropertiesSearchResult';
import { LuArmchair, LuBath, LuBed } from 'react-icons/lu';
import { NumericFormat } from 'react-number-format';
import { HiOutlineLocationMarker } from 'react-icons/hi';

type Props = {};

interface OptionsState {
  search: any;
  page: number;
  minBedRoom: number;
  maxBedRoom: number;
  maxPrice: number;
  minPrice: number;
  propertyType: string;
  furnishType: string;
  amenities: string[];
  sort: string;
}

const Search = (props: Props) => {
  const [queryParameters] = useSearchParams();
  const [query, setQuery] = useState(queryParameters.getAll('search') || '');
  const [currentPage, setCurrentPage] = useState(1);

  const [url, setUrl] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<OptionsState>({
    search: query,
    page: currentPage,
    minBedRoom: 0,
    maxBedRoom: 0,
    minPrice: 0,
    maxPrice: 0,
    propertyType: '',
    furnishType: '',
    amenities: [],
    sort: '',
  });

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const { data, isFetching } = useSearchQuery(url);

  const pages = Array.from(
    { length: data?.data?.listings?.last_page },
    (_, index) => index + 1
  );

  useEffect(() => {
    const link = objectToQueryString(options);
    const newUrl = window.location.pathname + '?' + link;
    window.history.pushState({ path: newUrl }, '', newUrl);
  }, [options]);

  const updateSearchParamValue = () => {
    let updatedOptions = { ...options, search: query };
    const link = objectToQueryString(updatedOptions);
    const newUrl = window.location.pathname + '?' + link;
    window.history.pushState({ path: newUrl }, '', newUrl);
    setUrl(link);
  };

  return (
    <WebsiteLayout
      title="Search for Prroperty"
      link="https://rentaly.rent/search"
    >
      <div className="container mx-auto md:px-0 px-5">
        <div className="my-[50px] relative md:w-[600px] w-full shadow-md rounded-full mx-auto">
          <input
            name="query"
            type="text"
            placeholder="Location"
            className="px-6 py-4 bg-white rounded-full md:w-[400px] w-full cursor-pointer"
            defaultValue={options.search}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button
            className="text-white text-sm py-[5px] px-6 my-3 mr-3 rounded-full bg-blue absolute right-0 top-0 bottom-0"
            onClick={() => updateSearchParamValue()}
          >
            Search
          </button>
        </div>
        <div className="py-4 md:flex block items-center justify-between">
          <div>
            <h3 className="font-bold text-3xl capitalize">
              Search Result
              {data?.data?.listings && data?.data?.listings?.total > 1
                ? 's'
                : ''}
            </h3>
            {data?.data?.listings && (
              <p className="text-gray">
                {data?.data?.listings?.total} result
                {data?.data?.listings?.total > 1 && 's'}
              </p>
            )}
          </div>
          <div className="flex gap-[10px] justify-between">
            <button
              onClick={handleToggle}
              className="text-white text-sm py-[5px] px-6 my-3 mr-3 rounded-full bg-blue flex items-center gap-2"
            >
              <VscSettings />
              Filter
            </button>
            <span className="flex gap-[5px] items-center text-blue rounded-full">
              <div className="flex items-center gap-1">
                <BiSortAlt2 />
                <span>sort</span>
              </div>
              <select
                name="sort"
                className="rounded-full cursor-pointer text-sm bg-blue text-white p-2"
                value={options.sort}
                onChange={(e) => {
                  setOptions({ ...options, sort: e.target.value });
                }}
              >
                <option value="">None</option>
                <option value="createdAt">Ascending</option>
                <option value="-createdAt">Descending</option>
                <option value="annualRent">Amount</option>
              </select>
            </span>
          </div>
        </div>

        {isFetching && <LoadingComponent />}

        {data?.data?.listings?.data.length === 0 && (
          <p className="text-center py-40">No result for this search</p>
        )}
        <Sidebar
          isOpen={isOpen}
          handleToggle={handleToggle}
          options={options}
          setOptions={setOptions}
          updateSearchParamValue={updateSearchParamValue}
        />

        {data?.data?.listings?.data && !isFetching && (
          <div>
            <div className="grid md:grid-cols-4 grid-cols-1 gap-5">
              {data?.data?.listings?.data.map(
                ({
                  noOfBedrooms,
                  noOfLivingRoom,
                  annualRent,
                  city,
                  state,
                  images,
                  propertyType,
                  noOfBathrooms,
                  id,
                }: any) => (
                  <div key={id}>
                    <div className="shadow-md rounded-md">
                      <img
                        src={images[0].imageUrl}
                        alt="coverImage"
                        loading="lazy"
                        className="h-[200px] w-full rounded-t-md"
                      />
                      <div className="p-3">
                        <h3 className="capitalize font-bold">{propertyType}</h3>
                        <div className="flex gap-2 capitalize">
                          <HiOutlineLocationMarker className="mt-1" />
                          <div className="flex-1">
                            <span>{city + ', ' + state}</span>
                          </div>
                        </div>
                        <div className="font-bold text-gray">
                          <NumericFormat
                            value={annualRent}
                            prefix="&#8358;"
                            thousandSeparator=","
                            displayType="text"
                          />
                          /year
                        </div>
                        <div className="flex mt-2 items-center font-light text-black1">
                          <LuArmchair />
                          <div className="flex-1 ml-2">Living Room</div>
                          <span>{noOfLivingRoom}</span>
                        </div>
                        <div className="flex mt-2 items-center font-light text-black1">
                          <LuBed />
                          <div className="flex-1 ml-2">Bedrooms</div>
                          <span>{noOfBedrooms}</span>
                        </div>
                        <div className="flex mt-2 items-center font-light text-black1">
                          <LuBath />
                          <div className="flex-1 ml-2">Bathrooms</div>
                          <span>{noOfBathrooms}</span>
                        </div>
                        <Link
                          to={`/listings/${id}`}
                          className="bg-blue text-center p-2 block w-full text-white mt-3 rounded-md cursor-pointer hover:opacity-80 transition ease-in-out duration-300"
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}

        {data?.data?.listings?.last_page > 1 && (
          <div className="h-[7vh] flex justify-between items-center">
            <span className="text-gray">
              Showing page {currentPage} of {data?.data?.listings?.last_page}
            </span>
            <span className="flex gap-3 items-center">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={
                  data?.data?.listings?.current_page === 1 ? true : false
                }
                className={` ${
                  data?.data?.listings?.current_page === 1
                    ? 'opacity-50 cursor-not-allowed'
                    : ''
                }`}
              >
                <FaChevronCircleLeft />
              </button>
              {pages.map((page) => (
                <button
                  key={page}
                  onClick={() => setCurrentPage(page)}
                  disabled={currentPage === page}
                  className={`border-[1px] rounded-md p-2 border-black text-xs ${
                    currentPage === page
                      ? ' cursor-not-allowed bg-blue text-white'
                      : ''
                  }`}
                >
                  {page}
                </button>
              ))}
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === data?.data?.listings?.last_page}
                className={`${
                  currentPage === data?.data?.listings?.last_page
                    ? 'opacity-50'
                    : ''
                }`}
              >
                <FaChevronCircleRight />
              </button>
            </span>
          </div>
        )}
      </div>
    </WebsiteLayout>
  );
};

export default Search;
