import { GrClose } from 'react-icons/gr';
import './styles.css';
import PropertyChecklist from './PropertyChecklist';
import SelectPropertyType from './SelectPropertyType';
import { objectToQueryString } from '../../helpers/objectToQueryString';
import { useNavigate } from 'react-router-dom';
import { getStringifiedData } from '../../helpers/useTransformData';
import { Slider } from 'antd';
import { NumericFormat } from 'react-number-format';

type Props = {
  isOpen: boolean;
  handleToggle: any;
  options: any;
  setOptions: any;
  updateSearchParamValue: any;
};

const Sidebar = ({
  isOpen,
  handleToggle,
  options,
  setOptions,
  updateSearchParamValue,
}: Props) => {
  const handleChange = (e: any) => {
    setOptions({ ...options, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();

  const furnishOptions = getStringifiedData('furnishType');
  const propertyOptions = getStringifiedData('propertyType');
  const amenitiesOptions = getStringifiedData('amenities');

  const handleReset = () => {
    setOptions({
      search: options?.search,
      minBedRoom: 0,
      maxBedRoom: 0,
      minPrice: 0,
      maxPrice: 0,
      propertyType: '',
      furnishType: '',
      amenities: [],
      page: 1,
      sort: '',
    });
    if (
      options?.search === '' ||
      (Array.isArray(options?.search) &&
        options?.search.length === 1 &&
        options?.search[0] === '')
    ) {
      navigate('/search');
    } else {
      navigate(`/search?search=${options?.search}`);
    }
    updateSearchParamValue();
    handleToggle();
  };

  const handleUpdate = () => {
    console.log('options ==>', options);
    const link = objectToQueryString(options);
    console.log('update link ==>', link);
    const newUrl = window.location.pathname + '?' + link;
    window.history.pushState({ path: newUrl }, '', newUrl);
    updateSearchParamValue();
    handleToggle();
  };

  return (
    <div>
      <div
        className={`backdrop ${isOpen ? 'visible' : ''}`}
        onClick={handleToggle}
      ></div>
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        {isOpen && (
          <div className="flex flex-col gap-3 h-screen p-3">
            <div className="flex-none">
              <div className="flex justify-between items-center">
                <h3 className="text-2xl font-bold mb-3">Filter your results</h3>
                <GrClose
                  className="toggle-button cursor-pointer"
                  onClick={handleToggle}
                />
              </div>
            </div>
            <div className="grow overflow-y-scroll text-sm">
              <h3 className="font-bold mb-3">Bedrooms</h3>
              <div className="flex gap-4 items-center pb-2 mb-4 border-b-[1px] border-[#ddd]">
                <span className="w-full">
                  <label
                    htmlFor="minBedRoom"
                    className="font-gray block text-black"
                  >
                    Min. Bed
                  </label>
                  <select
                    name="minBedRoom"
                    className="px-6 py-4 my-3 rounded-md cursor-pointer bg-slate-100 text-black w-[100%]"
                    value={options.minBedRoom}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </select>
                </span>
                <span className="w-full">
                  <label
                    htmlFor="maxBedRoom"
                    className="font-gray block text-black"
                  >
                    Max. Bed
                  </label>
                  <select
                    name="maxBedRoom"
                    className="px-6 py-4 my-3 rounded-md cursor-pointer bg-slate-100 text-black w-[100%]"
                    value={options.maxBedRoom}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value={2} disabled={options.minBedRoom > 2}>
                      2
                    </option>
                    <option value={3} disabled={options.minBedRoom > 3}>
                      3
                    </option>
                    <option value={4} disabled={options.minBedRoom > 4}>
                      4
                    </option>
                    <option value={5} disabled={options.minBedRoom > 5}>
                      5
                    </option>
                  </select>
                </span>
              </div>
              <div className="pb-2 mb-4 border-b-[1px] border-[#ddd]">
                <h3 className="font-bold text-black mb-3">Price </h3>
                <div className="mx-[20px]">
                  <Slider
                    min={100000}
                    max={30000000}
                    range={true}
                    defaultValue={[400000, 20000000]}
                    onChangeComplete={(value) => {
                      setOptions({
                        ...options,
                        maxPrice: value[1],
                        minPrice: value[0],
                      });
                    }}
                  />
                </div>
                <div className="flex items-center justify-between">
                  <NumericFormat
                    value={100000}
                    prefix="&#8358;"
                    thousandSeparator=","
                    displayType="text"
                  />
                  <NumericFormat
                    value={30000000}
                    prefix="&#8358;"
                    thousandSeparator=","
                    displayType="text"
                  />
                </div>
              </div>
              <div className="flex gap-4 items-center pb-2 mb-4 border-b-[1px] border-[#ddd]">
                <span className="w-full">
                  <label
                    htmlFor="furnishType"
                    className="font-gray block text-black"
                  >
                    Furnished Properties
                  </label>
                  <select
                    name="furnishType"
                    className="px-6 py-4 my-3 rounded-md cursor-pointer bg-slate-100 text-black w-[100%]"
                    value={options.furnishType}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">None</option>
                    {furnishOptions.map(({ name, label }) => (
                      <option key={name} value={name}>
                        {label}
                      </option>
                    ))}
                  </select>
                </span>
              </div>
              <div className="pb-2 mb-4 border-b-[1px] border-[#ddd]">
                <h3 className="font-bold text-black mb-3">Property Type</h3>

                <SelectPropertyType
                  propertyOptions={propertyOptions}
                  setOptions={setOptions}
                  options={options}
                />
              </div>
              <div className="pb-2 mb-4 border-b-[1px] border-[#ddd]">
                <h3 className="font-bold text-black mb-3">Amenities</h3>
                <PropertyChecklist
                  waterHeater={amenitiesOptions?.waterHeater}
                  estateHousing={amenitiesOptions?.estateHousing}
                  internetService={amenitiesOptions?.internetService}
                  solarInverter={amenitiesOptions?.solarInverter}
                  sportsArea={amenitiesOptions?.sportsArea}
                  security={amenitiesOptions?.security}
                  wasteDisposalService={amenitiesOptions?.wasteDisposalService}
                  parkingSpace={amenitiesOptions?.parkingSpace}
                  setOptions={setOptions}
                  options={options}
                />
              </div>
            </div>
            <div className="flex-none ">
              <div className="flex gap-3 justify-between">
                <button
                  className="w-full text-center block bg-yellow text-white py-5
                  rounded-md cursor-pointer my-3 text-sm"
                  onClick={() => handleReset()}
                >
                  Reset
                </button>
                <button
                  className="w-full text-center block bg-blue text-white py-5
                  rounded-md cursor-pointer my-3 text-sm"
                  onClick={() => handleUpdate()}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
