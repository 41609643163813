type Props = {
  options: any;
  setOptions: any;
  propertyOptions: any;
};

const SelectPropertyType = ({
  options,
  setOptions,
  propertyOptions,
}: Props) => {
  return (
    <div className="grid grid-cols-2 gap-x-2 text-gray">
      {propertyOptions.map(({ name, label }) => (
        <div className="flex gap-2 items-center mb-2" key={name}>
          <input
            type="checkbox"
            checked={options.propertyType.includes(`${name}`)}
            onChange={() => setOptions({ ...options, propertyType: name })}
          />
          <span className="text-sm">{label} </span>
        </div>
      ))}
    </div>
  );
};

export default SelectPropertyType;
