import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const SliderComponent = ({ data }: any) => {
  return (
    <div>
      {Array.isArray(data) && data.length === 1 && (
        <img src={data[0].imageUrl} alt="" className="w-full h-[350px]" />
      )}
      {data.length === 1 ? (
        <img
          src={`${data[0].imageUrl}`}
          alt=""
          className="h-[500px] w-full slider-display-image"
          loading="lazy"
        />
      ) : (
        <Slide autoplay cssClass="z-10">
          {data.map((item, index) => (
            <div key={index} className="each-slide-effect h-[500px] rounded-md">
              <div
                className="slider-display-image"
                style={{
                  backgroundImage: `url(${item.imageUrl})`,
                  height: '500px',
                  width: '100%',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              ></div>
            </div>
          ))}
        </Slide>
      )}
    </div>
  );
};

export default SliderComponent;
