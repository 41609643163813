import { useQuery } from 'react-query';
import { apiConfig } from '../config/apiConfig';
import { axiosInstance } from '../utils/axiosInstance';
import { stringifyData } from '../helpers/useTransformData';

export const useSearchQuery = (searchItem) => {
  return useQuery({
    queryKey: ['search', searchItem],
    queryFn: () =>
      axiosInstance
        .get(`${apiConfig.SEARCH}?${searchItem}&per_page=10`)
        .then((res) => res.data),
    onSuccess: (data) => {
      const check = localStorage.getItem('propertyType');

      if (!check) {
        console.log('added ✅');
        stringifyData(data?.data?.propertyType, 'propertyType');
        stringifyData(data?.data?.furnishType, 'furnishType');
        stringifyData(data?.data?.amenities, 'amenities');
      } else return;
    },
    onError: (error) => {
      console.log('❌ Search', error);
    },
  });
};
