import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { axiosInstance } from '../utils/axiosInstance';
import { apiConfig } from '../config/apiConfig';

export const useSubscribeMutation = () => {
  return useMutation(
    (email: string) =>
      axiosInstance
        .post(`${apiConfig.NEWSLETTER}`, { email: email })
        .then((res) => res.data),
    {
      onSuccess: async (data: any) => {
        toast.success(data?.message);
        console.log('✅ SUBSCRIBED');
      },
      onError: (error: any) => {
        toast.error(error?.message);
        console.log('❌ SUBSCRIBED', error?.message);
      },
    }
  );
};
