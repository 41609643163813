import React from 'react';
import WebsiteLayout from '../components/layouts/WebsiteLayout';

type Props = {};

const Terms = (props: Props) => {
  return (
    <WebsiteLayout
      title="Terms and Conditions"
      link="https://rentaly.rent/terms"
    >
      <div className="container mx-auto md:px-0 px-5 mt-20">
        <h3 className="font-bold">Terms and Conditions</h3>
        <div className="mb-20 mt-10">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda
          minus aliquam veritatis nulla ex asperiores cupiditate facere velit,
          libero dicta ab nemo possimus at quos suscipit aspernatur unde ratione
          molestias. Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Assumenda minus aliquam veritatis nulla ex asperiores cupiditate
          facere velit, libero dicta ab nemo possimus at quos suscipit
          aspernatur unde ratione molestias. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Assumenda minus aliquam veritatis nulla
          ex asperiores cupiditate facere velit, libero dicta ab nemo possimus
          at quos suscipit aspernatur unde ratione molestias. Lorem ipsum dolor
          sit amet consectetur adipisicing elit. Assumenda minus aliquam
          veritatis nulla ex asperiores cupiditate facere velit, libero dicta ab
          nemo possimus at quos suscipit aspernatur unde ratione molestias.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda
          minus aliquam veritatis nulla ex asperiores cupiditate facere velit,
          libero dicta ab nemo possimus at quos suscipit aspernatur unde ratione
          molestias. Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Assumenda minus aliquam veritatis nulla ex asperiores cupiditate
          facere velit, libero dicta ab nemo possimus at quos suscipit
          aspernatur unde ratione molestias. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Assumenda minus aliquam veritatis nulla
          ex asperiores cupiditate facere velit, libero dicta ab nemo possimus
          at quos suscipit aspernatur unde ratione molestias. Lorem ipsum dolor
          sit amet consectetur adipisicing elit. Assumenda minus aliquam
          veritatis nulla ex asperiores cupiditate facere velit, libero dicta ab
          nemo possimus at quos suscipit aspernatur unde ratione molestias.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda
          minus aliquam veritatis nulla ex asperiores cupiditate facere velit,
          libero dicta ab nemo possimus at quos suscipit aspernatur unde ratione
          molestias.
        </div>
      </div>
    </WebsiteLayout>
  );
};

export default Terms;
