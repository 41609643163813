import { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import { LuArmchair, LuBath, LuBed } from 'react-icons/lu';
import WebsiteLayout from '../../components/layouts/WebsiteLayout';
import { useGetListingsQuery } from '../../hooks/useGetListingsQuery';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { NumericFormat } from 'react-number-format';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import LoadingComponent from '../../components/primary/LoadingComponent';

type Props = {};

const Listing = (props: Props) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data: listing, isFetching } = useGetListingsQuery();

  const pages = Array.from(
    { length: listing?.data?.listings?.last_page },
    (_, index) => index + 1
  );

  return (
    <WebsiteLayout title="Listing" link="https://rentaly.rent/listing">
      <Fade>
        <div className="container mx-auto md:py-[50px] py-20 md:px-0 px-5">
          <p className="text-4xl font-bold uppercase text-black1 text-center">
            Property Listing
          </p>
          <p className="text-gray mt-3 mb-20 text-center">
            A diverse collection of properties, for sale or lease including land
            and buildings.
          </p>

          {isFetching && <LoadingComponent />}

          {listing?.data?.listings && !isFetching && (
            <div>
              <div className="grid md:grid-cols-4 grid-cols-1 gap-5">
                {listing?.data?.listings?.data.map(
                  ({
                    noOfBedrooms,
                    noOfLivingRoom,
                    annualRent,
                    city,
                    state,
                    images,
                    propertyType,
                    noOfBathrooms,
                    id,
                  }: any) => (
                    <div key={id}>
                      <div className="shadow-md rounded-md">
                        <img
                          src={images[0].imageUrl}
                          alt="coverImage"
                          loading="lazy"
                          className="h-[200px] w-full rounded-t-md"
                        />
                        <div className="p-3">
                          <h3 className="capitalize font-bold">
                            {propertyType}
                          </h3>
                          <div className="flex gap-2 capitalize">
                            <HiOutlineLocationMarker className="mt-1" />
                            <div className="flex-1">
                              <span>{city + ', ' + state}</span>
                            </div>
                          </div>
                          <div className="font-bold text-gray">
                            <NumericFormat
                              value={annualRent}
                              prefix="&#8358;"
                              thousandSeparator=","
                              displayType="text"
                            />
                            /year
                          </div>
                          <div className="flex mt-2 items-center font-light text-black1">
                            <LuArmchair />
                            <div className="flex-1 ml-2">Living Room</div>
                            <span>{noOfLivingRoom}</span>
                          </div>
                          <div className="flex mt-2 items-center font-light text-black1">
                            <LuBed />
                            <div className="flex-1 ml-2">Bedrooms</div>
                            <span>{noOfBedrooms}</span>
                          </div>
                          <div className="flex mt-2 items-center font-light text-black1">
                            <LuBath />
                            <div className="flex-1 ml-2">Bathrooms</div>
                            <span>{noOfBathrooms}</span>
                          </div>
                          <Link
                            to={`/listings/${id}`}
                            className="bg-blue text-center p-2 block w-full text-white mt-3 rounded-md cursor-pointer hover:opacity-80 transition ease-in-out duration-300"
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="h-[7vh] flex justify-between items-center">
                <span className="text-gray">
                  Showing page {currentPage} of{' '}
                  {listing?.data?.listings?.last_page}
                </span>
                <span className="flex gap-3 items-center">
                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={
                      listing?.data?.listings?.current_page === 1 ? true : false
                    }
                    className={` ${
                      listing?.data?.listings?.current_page === 1
                        ? 'opacity-50 cursor-not-allowed'
                        : ''
                    }`}
                  >
                    <FaChevronCircleLeft />
                  </button>
                  {pages.map((page) => (
                    <button
                      key={page}
                      onClick={() => setCurrentPage(page)}
                      disabled={currentPage === page}
                      className={`border-[1px] rounded-md p-2 border-black text-xs ${
                        currentPage === page
                          ? ' cursor-not-allowed bg-blue text-white'
                          : ''
                      }`}
                    >
                      {page}
                    </button>
                  ))}
                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={
                      currentPage === listing?.data?.listings?.last_page
                    }
                    className={`${
                      currentPage === listing?.data?.listings?.last_page
                        ? 'opacity-50'
                        : ''
                    }`}
                  >
                    <FaChevronCircleRight />
                  </button>
                </span>
              </div>
            </div>
          )}
        </div>
      </Fade>
    </WebsiteLayout>
  );
};

export default Listing;
