import React from 'react';
import WebsiteLayout from '../components/layouts/WebsiteLayout';
import { faq } from '../data';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

const SideImage = require('../assets/sideimage.png');

const Faq = () => {
  return (
    <WebsiteLayout
      title="Frequently Asked Questions"
      link="https://rentaly.rent/faq"
    >
      <Fade>
        <p className="mt-[70px] md:text-4xl text-2xl font-bold text-center uppercase text-black1">
          You’ve got questions?
        </p>
        <p className="mt-[10px] md:text-4xl text-2xl font-bold text-center uppercase text-black1">
          WE’VE GOT ANSWERS?
        </p>
        <p className="text-center mx-auto md:max-w-[700px] w-10/12 text-black1 mt-3">
          Got questions? We've got answers! Explore our Frequently Asked
          Questions to find quick solutions and insights about using Rentaly.
        </p>

        <div className="container mx-auto my-20">
          <div className="md:flex gap-4 md:px-0 px-5">
            <div className="md:w-7/12">
              <Accordion preExpanded={[1]}>
                {faq.map(({ id, header, body }) => (
                  <AccordionItem key={id} uuid={id} className="">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span className="uppercase font-bold">{header}</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="text-gray">{body}</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
            <div className="md:w-5/12">
              <img src={SideImage} alt="" className="rounded-[20px]" />
            </div>
          </div>
        </div>
        <div className="container mx-auto my-20 md:px-0 px-5">
          <div className="md:flex block bg-blue rounded-lg p-10">
            <div className="md:w-10/12 w-full">
              <p className="uppercase text-white font-bold">
                Still have questions?
              </p>
              <p className="text-white md:w-7/12 w-full font-light mt-2">
                If you don't find what you're looking for, feel free to reach
                out to our support team for personalized assistance. Let's make
                your Rentaly experience seamless and enjoyable!
              </p>
            </div>
            <div className="md:w-2/12 w-full mt-[30px]">
              <Link
                to="mailto:info@rentaly.rent"
                target="_blank"
                className="p-4 bg-white rounded-[40px] text-light"
              >
                Contact support
              </Link>
            </div>
          </div>
        </div>
      </Fade>
    </WebsiteLayout>
  );
};

export default Faq;
