import React, { useState } from 'react';
import WebsiteLayout from '../components/layouts/WebsiteLayout';
import { useGetPropertiesQuery } from '../hooks/useGetPropertiesQuery';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import LoadingComponent from '../components/primary/LoadingComponent';
import PropertiesCard from '../components/primary/PropertiesCard';

type Props = {};

const Properties = (props: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data: properties, isFetching } = useGetPropertiesQuery();

  const pages = Array.from(
    { length: properties?.data?.last_page },
    (_, index) => index + 1
  );

  return (
    <WebsiteLayout title="Properties" link="https://rentaly.rent/property">
      <div className="container mx-auto md:py-[50px] py-20 md:px-0 px-5">
        <p className="text-4xl font-bold uppercase text-black1 text-center">
          Property Portfolio
        </p>
        <p className="text-gray mt-3 mb-20 text-center">
          A diverse collection of properties, for sale or lease including land
          and buildings.
        </p>
        {isFetching && <LoadingComponent />}

        {properties?.data && !isFetching && (
          <div>
            <div className="grid md:grid-cols-4 grid-cols-1 gap-5">
              {properties?.data?.data.map(
                ({
                  askingPrice,
                  tag,
                  title,
                  address,
                  principalPhone,
                  principalWhatsapp,
                  images,
                  id,
                }: any) => (
                  <PropertiesCard
                    id={id}
                    askingPrice={askingPrice}
                    tag={tag}
                    title={title}
                    address={address}
                    principalPhone={principalPhone}
                    principalWhatsapp={principalWhatsapp}
                    images={images}
                  />
                )
              )}
            </div>
            <div className="h-[7vh] flex justify-between items-center mt-6">
              <span className="text-gray">
                Showing page {currentPage} of {properties?.data?.last_page}
              </span>
              <span className="flex gap-3 items-center">
                <button
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={properties?.data?.current_page === 1 ? true : false}
                  className={` ${
                    properties?.data?.current_page === 1
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                >
                  <FaChevronCircleLeft />
                </button>
                {pages.map((page) => (
                  <button
                    key={page}
                    onClick={() => setCurrentPage(page)}
                    disabled={currentPage === page}
                    className={`border-[1px] rounded-md p-2 border-black text-xs ${
                      currentPage === page
                        ? ' cursor-not-allowed bg-blue text-white'
                        : ''
                    }`}
                  >
                    {page}
                  </button>
                ))}
                <button
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === properties?.data?.last_page}
                  className={`${
                    currentPage === properties?.data?.last_page
                      ? 'opacity-50'
                      : ''
                  }`}
                >
                  <FaChevronCircleRight />
                </button>
              </span>
            </div>
          </div>
        )}
      </div>
    </WebsiteLayout>
  );
};

export default Properties;
