type Props = {
  waterHeater: boolean;
  estateHousing: boolean;
  internetService: boolean;
  solarInverter: boolean;
  sportsArea: boolean;
  security: boolean;
  wasteDisposalService: boolean;
  parkingSpace: boolean;
  options: any;
  setOptions: any;
};

const PropertyChecklist = ({
  waterHeater,
  estateHousing,
  internetService,
  solarInverter,
  sportsArea,
  security,
  wasteDisposalService,
  parkingSpace,
  options,
  setOptions,
}: Props) => {
  const data = {
    waterHeater: waterHeater,
    estateHousing: estateHousing,
    internetService: internetService,
    solarInverter: solarInverter,
    sportsArea: sportsArea,
    security: security,
    wasteDisposalService: wasteDisposalService,
    parkingSpace: parkingSpace,
  };

  const selectAmenity = (amenity) => {
    const isSelected = options.amenities.includes(amenity);

    setOptions((prevOptions) => {
      if (isSelected) {
        return {
          ...prevOptions,
          amenities: prevOptions.amenities.filter(
            (selectedAmenity) => selectedAmenity !== amenity
          ),
        };
      } else {
        return {
          ...prevOptions,
          amenities: [...prevOptions.amenities, amenity],
        };
      }
    });
  };

  return (
    <div className="grid grid-cols-2 gap-x-2 text-gray">
      {Object.entries(data).map(([key, value]) => (
        <div className="flex gap-2 items-center mb-2" key={key}>
          <input
            type="checkbox"
            checked={options.amenities.includes(`${key}`)}
            onChange={() => selectAmenity(`${key}`)}
          />
          <span className="text-sm">
            {key === 'waterHeater' && 'Water Heater'}
            {key === 'estateHousing' && 'Estate Housing'}
            {key === 'internetService' && 'Internet Service'}
            {key === 'solarInverter' && 'Solar/Inverter'}
            {key === 'sportsArea' && 'Sport Area'}
            {key === 'security' && 'Security'}
            {key === 'wasteDisposalService' && 'Waste Disposal Service'}
            {key === 'parkingSpace' && 'Parking Space'}
          </span>
        </div>
      ))}
    </div>
  );
};

export default PropertyChecklist;
