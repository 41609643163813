import React from 'react';
import WebsiteLayout from '../components/layouts/WebsiteLayout';
import { coreValues, team } from '../data';
import { Fade } from 'react-awesome-reveal';
import NewsLetter from '../components/primary/NewsLetter';

type Props = {};
const bgVideo = require('../assets/rentalybg.mp4');
const Tenant = require('../assets/tenant.png');
const Landlord = require('../assets/landlord.png');
const Avatar = require('../assets/avatar.png');
const placeholder = require('../assets/placeholder.png');

const containerStyle = 'container mx-auto md:py-[50px] py-20 md:px-0 px-5';

const About = (props: Props) => {
  return (
    <WebsiteLayout title="About" link="https://rentaly.rent/about">
      <div className="mx-auto">
        <div className="relative">
          <div className="container mx-auto md:py-[250px] py-20 md:px-0 px-5">
            <Fade>
              <p className="text-4xl font-bold uppercase text-black1">
                Everything you need to <br />
                know about who we are <br />
                and what we do
              </p>
              <p className="md:w-6/12 w-full text-gray mt-3">
                Welcome to Rentaly, your trusted partner in transforming the
                home rental experience in Nigeria. At Rentaly, we are not just a
                platform; we are a movement that seeks to transform the way
                people experience home rentals in Nigeria.
              </p>
            </Fade>
          </div>
          <img
            src={placeholder}
            alt=""
            className="top-0 right-0 absolute h-[600px] md:block hidden"
          />
        </div>
        <Fade>
          <div className={containerStyle}>
            <h3 className="uppercase text-3xl text-center font-bold">
              Our History
            </h3>
            <div className="text-center text-gray md:w-6/12 w-10/12 mx-auto">
              As we look ahead, Rentaly remains dedicated to shaping the future
              of home rentals in Nigeria
            </div>
            <div className="md:flex flex-row my-10 md:gap-20">
              <div className="md:w-7/12 w-full">
                <video
                  src={bgVideo}
                  autoPlay
                  loop
                  muted
                  style={{
                    width: '100vw',
                    padding: 0,
                    margin: 0,
                    borderRadius: '40px',
                  }}
                />
              </div>
              <div className="md:w-5/12 mt-10">
                <h3 className="text-2xl text-black font-bold md:mt-0 mt-10">
                  AN OVERVIEW OF RENTALY
                </h3>
                <p className="text-gray mt-4 text-2xl text-medium">
                  Founded in 2023, Rentaly emerged with a mission to
                  revolutionize the home rental experience in Nigeria. Our
                  journey started with a commitment to transparency, efficiency,
                  and empowerment, aiming to connect landlords and tenants
                  directly, disrupting the conventional rental landscape.
                </p>
              </div>
            </div>
          </div>
          <div className={containerStyle}>
            <h3 className="uppercase text-3xl text-center font-bold">
              MADE FOR TENANTS AND LANDLORDS
            </h3>
            <div className="text-center text-gray">
              Say goodbye to unnecessary hassles, exorbitant agent fees, and the
              stress of traditional property renting.
            </div>

            <div className="md:flex flex-row my-10 gap-10">
              <div className="md:w-6/12 w-full rounded-md bg-blue p-3 text-white md:mb-0 mb-3">
                <img src={Landlord} alt="Rently banner" className="mx-auto" />
                <div className="uppercase text-center text-white text-2xl mb-3 font-bold">
                  Landlord
                </div>
                <p className="md:px-10">
                  From the outset, Rentaly has been tailored to meet the
                  specific needs of landlords. We understand the challenges of
                  property management and the desire for control over your
                  investments. Our platform empowers landlords by providing
                  efficient tools for tenant placement, transparent processes,
                  and a community-driven approach.
                </p>
              </div>
              <div className="md:w-6/12 w-full rounded-md bg-blue p-3 text-white">
                <img src={Tenant} alt="Rently banner" className="mx-auto" />
                <div className="uppercase text-center text-white text-2xl mb-3 font-bold">
                  Tenants
                </div>
                <p className="md:px-10">
                  Designed with tenants in mind, Rentaly offers a user-friendly
                  experience focused on simplicity and choice. Whether you're
                  seeking a cozy apartment or a spacious house, our platform
                  connects you directly with landlords, eliminating unnecessary
                  hurdles and reducing the financial burden of agent fees.
                </p>
              </div>
            </div>
          </div>
          <div className={containerStyle}>
            <h3 className="uppercase text-3xl text-center font-bold">
              Guided by our core values
            </h3>
            <div className="text-center text-gray max-w-[600px] mx-auto">
              Our core values define who we are and guide every aspect of
              Rentaly. As we continue to grow and evolve, these principles
              remain the foundation of our commitment to excellence in the home
              rental experience.{' '}
            </div>
            <div className="grid md:grid-cols-3 md:gap-4 grid-cols-1 mt-20">
              {coreValues.map(({ id, title, icon, desc }) => (
                <div className="flex gap-4 mt-2 md:mt-0" key={id}>
                  <img src={icon} alt="" className="h-[70px]" />
                  <div>
                    <h3 className="text-black font-bold md:text-2xl text-base uppercase">
                      {title}
                    </h3>
                    <p className="mt-3 text-gray">{desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={containerStyle}>
            <h3 className="uppercase text-3xl text-center font-bold">
              Meet our team
            </h3>
            <p className="text-center text-gray">
              Diam massa ut vitae ante risus rhoncus purus risus viverra.
              <br />
              Dignissim sapien tempus eu sit nibh.
            </p>
            <div className="grid md:grid-cols-3 grid-cols-2 gap-8 mt-4">
              {team.map(({ id, name, role }) => (
                <div className="" key={id}>
                  <img
                    src={Avatar}
                    alt=""
                    className="w-full rounded-t-[50px] rounded-bl-[20px] rounded-br-[50px]"
                  />
                  <div>
                    <h3 className="text-black font-bold md:text-2xl uppercase">
                      {name}
                    </h3>
                    <p className="md:mt-3 text-gray">{role}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <NewsLetter />
        </Fade>
      </div>
    </WebsiteLayout>
  );
};

export default About;
