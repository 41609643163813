import { useNavigate } from 'react-router-dom';

import Lottie from 'lottie-react';

import error from '../assets/error.json';

const Error = () => {
  const navigate = useNavigate();

  return (
    <center>
      <Lottie animationData={error} className="h-[300px] w-[300px] mt-[10vh]" />
      <button
        onClick={() => navigate(-1)}
        className="px-4 block mx-auto bg-blue text-white py-5 rounded-md cursor-pointer mt-4"
      >
        Go Back
      </button>
    </center>
  );
};

export default Error;
