import { useQuery } from 'react-query';
import { axiosInstance } from '../utils/axiosInstance';
import { apiConfig } from '../config/apiConfig';

export const useGetOneListingQuery = (id: any) => {
  return useQuery({
    queryKey: 'useGetOneListingsQuery',
    queryFn: () =>
      axiosInstance
        .get(`${apiConfig.LISTING}${id}`)
        .then((res: any) => res.data),
    onSuccess: (data) => {
      console.log('✅ GET ONE LISTING');
    },
    onError: (error: any) => {
      console.log('❌ GET ONE LISTING ==>', error.message);
    },
  });
};
