import { useQuery } from 'react-query';
import { axiosInstance } from '../utils/axiosInstance';
import { apiConfig } from '../config/apiConfig';

export const useGetListingsQuery = () => {
  return useQuery({
    queryKey: 'useGetListingsQuery',
    queryFn: () =>
      axiosInstance.get(`${apiConfig.LISTING}`).then((res: any) => res.data),
    onSuccess: (data) => {
      console.log('✅ GET LISTINGS');
    },
    onError: (error: any) => {
      console.log('❌ GET LISTINGS ==>', error.message);
    },
  });
};
