import React, { useEffect, useState } from 'react';
import WebsiteLayout from '../components/layouts/WebsiteLayout';
import { testimonials, whyus } from '../data';
import { Typewriter } from 'react-simple-typewriter';
import { Link, useNavigate } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import { useGetListingsQuery } from '../hooks/useGetListingsQuery';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { LuArmchair, LuBath, LuBed } from 'react-icons/lu';
import { NumericFormat } from 'react-number-format';
import NewsLetter from '../components/primary/NewsLetter';
import 'react-slideshow-image/dist/styles.css';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FaAngleRight } from 'react-icons/fa';

const cover = require('../assets/coverpicture.png');
const coverwithlogo = require('../assets/coverwithlogo.png');
const keyIcon = require('../assets/keyIcon.png');
const Privacyicon = require('../assets/privacyicon.png');
const smiling = require('../assets/smiling.png');
const home = require('../assets/home.png');
const cta = require('../assets/cta.png');
const bgVideo = require('../assets/rentalybg.mp4');

const Home = () => {
  const { data: listings } = useGetListingsQuery();

  const [query, setQuery] = useState('');
  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);

  const placeholders = ['Lagos', 'Mansion', 'Kwara', 'Duplex'];

  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentPlaceholderIndex(
        (prevIndex) => (prevIndex + 1) % placeholders.length
      );
    }, 3000);

    return () => clearInterval(intervalId);
  }, [placeholders.length]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <WebsiteLayout title="Home" link="/">
      <div className="bg-blue mx-auto relative pt-[50px] md:pb-0 pb-40">
        <div className="container mx-auto md:px-0 px-5 relative">
          <Fade>
            <section className=" text-white md:text-[30px] text-[25px] sentencecase font-black">
              <div>Ever experienced housing agent's issues?</div>
              <div className="uppercase md:text-[80px] text-md">
                <Typewriter
                  words={['High agent fee', 'Agent stress']}
                  loop={false}
                  cursor
                  cursorStyle="|"
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </div>
            </section>
            <p className="text-white font-light">
              Rentaly would connect you directly to landlords.
            </p>
            <div className="mt-4 relative md:w-[400px] w-full">
              <input
                type="text"
                placeholder={placeholders[currentPlaceholderIndex]}
                className="px-6 py-4 bg-white rounded-full md:w-[400px] w-full cursor-pointer"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                autoFocus
              />
              <button
                disabled={query === ''}
                onClick={() => navigate(`/search?search=${query}`)}
                className="text-white text-sm py-[5px] px-6 my-3 mr-3 rounded-full bg-blue absolute right-0 top-0 bottom-0"
              >
                Search
              </button>
            </div>
          </Fade>
        </div>
        <img
          src={home}
          alt=""
          className="w-full md:-mt-[150px] hidden md:block"
          loading="lazy"
        />
      </div>
      <Fade>
        <div className="container mx-auto md:flex md:py-[50px] py-20 md:px-0 px-5 gap-20">
          <div className="md:w-6/12">
            <p className="text-4xl font-bold uppercase text-black1">
              Why Rentaly?
            </p>
            <p className="md:w-7/12 w-full text-gray mt-3 mb-20">
              We’re here to revolutionize the way you find and rent homes in
              Nigeria. Say goodbye to unnecessary hassles, exorbitant agent fees
              & get
            </p>
            {whyus.map(({ id, icon, title, body }) => (
              <div key={id} className="flex gap-4 mb-10">
                <img src={icon} alt="" className="h-[60px]" />
                <div>
                  <h3 className="font-bold text-black1 uppercase">{title}</h3>
                  <p className="mt-2 text-gray">{body}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="md:w-6/12">
            <img src={cover} alt="" />
          </div>
        </div>

        <div className="container mx-auto md:py-[50px] py-20 md:px-0 px-5">
          <p className="text-4xl font-bold uppercase text-black1 text-center">
            NEW PROPERTY LISTINGS
          </p>
          <p className="text-gray mt-3 mb-20 text-center">
            We Are Excited to Unveil Some of Our New Listings
          </p>
          {listings && (
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={responsive}
              ssr={true}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={4000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              itemClass="carousel-item-padding-40-px"
            >
              {listings?.data?.listings?.data
                .slice(0, 10)
                .map(
                  ({
                    noOfBedrooms,
                    noOfLivingRoom,
                    annualRent,
                    city,
                    state,
                    images,
                    propertyType,
                    noOfBathrooms,
                    id,
                  }: any) => (
                    <div key={id} className="each-slide-effect px-2">
                      <div style={{ backgroundImage: `url(${images[0]})` }}>
                        <img
                          src={images[0].imageUrl}
                          alt="coverImage"
                          loading="lazy"
                          className="h-[200px] w-full rounded-t-md"
                        />
                        <div className="p-3">
                          <h3 className="capitalize font-bold">
                            {propertyType}
                          </h3>
                          <div className="flex gap-2 capitalize">
                            <HiOutlineLocationMarker className="mt-1" />
                            <div className="flex-1 capitalize text-ellipsis overflow-hidden ">
                              <span>{city + ', ' + state}</span>{' '}
                            </div>
                          </div>
                          <div className="">
                            <NumericFormat
                              value={annualRent}
                              prefix="&#8358;"
                              thousandSeparator=","
                              displayType="text"
                            />
                            /year
                          </div>
                          <div className="flex mt-2 items-center font-light text-black1">
                            <LuArmchair />
                            <div className="flex-1 ml-2">Living Room</div>
                            <span>{noOfLivingRoom}</span>
                          </div>
                          <div className="flex mt-2 items-center font-light text-black1">
                            <LuBed />
                            <div className="flex-1 ml-2">Bedrooms</div>
                            <span>{noOfBedrooms}</span>
                          </div>
                          <div className="flex mt-2 items-center font-light text-black1">
                            <LuBath />
                            <div className="flex-1 ml-2">Bathrooms</div>
                            <span>{noOfBathrooms}</span>
                          </div>
                          <Link
                            to={`/listings/${id}`}
                            className="bg-blue p-2 w-full inline-block text-center text-white mt-3 rounded-md cursor-pointer hover:opacity-80 transition ease-in-out duration-300"
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                )}
            </Carousel>
          )}
          {!listings && (
            <p className="text-center text-gray py-40">Loading Properties</p>
          )}
          <div className="float-right">
            <span
              className="flex gap-2 items-center cursor-pointer font-bold text-blue"
              onClick={() => navigate('/listings')}
            >
              <span>See more</span>
              <FaAngleRight />
            </span>
          </div>
        </div>

        <div className="container mx-auto md:flex md:py-[50px] py-20 md:px-0 px-5 gap-20">
          <div className="md:w-6/12">
            <img src={coverwithlogo} alt="" />
          </div>
          <div className="md:w-6/12">
            <p className="text-4xl font-bold uppercase text-black1 mt-20 md:mt-0">
              Our Commitment
            </p>
            <p className="md:w-8/12 text-gray mt-3 mb-10">
              At Rentaly, our commitment goes beyond just providing a platform
              for home rentals in Nigeria.
            </p>
            <div className="text-black1 text-xl mb-3">
              We are dedicated to redefining the entire rental experience,
              ensuring that both landlords and tenants benefit from a trusted,
              transparent, and efficient process.
            </div>
            <div className="mt-3 md:flex gap-3">
              <div className="flex-1 md:mt-0 mt-5">
                <img src={keyIcon} alt="" />
                <h3 className="uppercase text-black1 mt-4 font-bold">
                  Transparency First
                </h3>
                <p className="md:w-10/12 mt-2 text-black1">
                  We believe in openness and clarity. Our commitment is to
                  provide transparent information about every property, enabling
                  tenants to make informed decisions{' '}
                </p>
              </div>
              <div className="flex-1 md:mt-0 mt-5">
                <img src={Privacyicon} alt="" />
                <h3 className="uppercase text-black1 mt-4 font-bold">
                  Community Building
                </h3>
                <p className="md:w-10/12 mt-2 text-black1">
                  Rentaly is more than just a rental platform; it's a community.
                  We are committed to fostering connections between landlords
                  and tenants.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto md:flex block md:py-[50px] py-20 md:px-0 px-5 gap-20">
          <div className="md:w-8/12 w-full">
            <video
              src={bgVideo}
              autoPlay
              loop
              muted
              style={{
                width: '100vw',
                padding: 0,
                margin: 0,
                borderRadius: '40px',
              }}
            />
          </div>
          <div className="md:w-4/12 w-full">
            <h3 className="uppercase text-2xl mt-[15px] font-bold">
              Whether you're a Landlord or a tenant, We can help you move
              forward!
            </h3>
            <div className="md:flex block gap-4 mt-20">
              <div className="md:w-6/12 w-full mb-5">
                <h3 className="text-2xl font-bold text-black">Landlords</h3>
                <p className="text-gray text-base">
                  Rentaly is not just a platform; it's a partner in your
                  property journey. We are committed to empowering you with the
                  tools and resources needed to find reliable tenants,
                </p>
              </div>
              <div className="md:w-6/12 w-full mb-5">
                <h3 className="text-2xl font-bold text-black">Tenants</h3>
                <p className="text-gray text-base">
                  Tenant satisfaction is at the core of our commitment. We
                  strive to create a secure and user-friendly environment for
                  tenants, ensuring a stress-free rental experience.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-[1600px] mx-auto relative">
          <img
            src={smiling}
            alt=""
            className="w-[40%]  absolute md:block hidden mt-20"
          />
          <div className="container mx-auto flex md:py-[50px] py-20 md:px-0 px-5">
            <div className="w-6/12 md:block hidden"></div>
            <div className="md:w-6/12">
              <p className="text-4xl font-bold uppercase text-black1">
                Testimonials
              </p>
              <p className="md:w-9/12 w-full text-gray mt-3 mb-5">
                Cutting out the stress... Hear Our Clients' Stories!
              </p>
              <div className="grid grid-cols-2">
                {testimonials.map(({ id, picture, name, testimonial }) => (
                  <div key={id} className="mt-4">
                    <img src={picture} alt="" />
                    <h3 className="mt-2 font-bold">{name}</h3>
                    <p className="text-gray mt-2 w-10/12">{testimonial}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto">
          <img src={cta} alt="" className="w-full mb-20" />
        </div>
        <NewsLetter />
      </Fade>
    </WebsiteLayout>
  );
};

export default Home;
