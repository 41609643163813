import { ReactNode, useEffect, useState } from 'react';
import { Link, ScrollRestoration, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MdEmail } from 'react-icons/md';
import { ImPhone } from 'react-icons/im';
import { BsTwitter, BsInstagram, BsYoutube } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import { IoMenu } from 'react-icons/io5';
import { IoMdClose } from 'react-icons/io';
import { Modal } from 'antd';
import { CgRadioCheck, CgRadioChecked } from 'react-icons/cg';
import { Fade } from 'react-awesome-reveal';

const logo = require('../../assets/logo.png');

const tenant = require('../../assets/tenant.png');
const landlord = require('../../assets/landlord.png');

interface MyComponentProps {
  children: ReactNode;
  title: string;
  link: string;
}

function LogoComponent() {
  return <img src={logo} alt="Rentaly" className="h-[50px]" />;
}

const IconLinkStyle = 'flex py-2 gap-3 items-center cursor-pointer text-white';
const NormalLinkStyle = 'block py-2 cursor-pointer';

const WebsiteLayout = ({ children, title, link }: MyComponentProps) => {
  let location = useLocation();
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState(false);

  const [showComponent, setShowComponent] = useState(false);

  const [user, setUser] = useState('');

  const activeClass = 'bg-blue text-white';
  const inActiveClass = 'text-gray bg-white';

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPercentage =
        (window.scrollY / (document.body.scrollHeight - window.innerHeight)) *
        100;

      if (location.pathname !== '/search' && scrollPercentage > 4) {
        setShowComponent(true);
      } else {
        setShowComponent(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname]);

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div className="relative">
      <Modal open={open} onCancel={handleCancel} footer={null}>
        <div className="glass-effect w-full mx-auto p-5">
          <div>
            <p className="uppercase font-bold text-2xl mb-3 text-white">
              Join as a landlord or tenant
            </p>
            <div className="block md:flex gap-4 my-10">
              <div
                className={`md:w-6/12 p-3 cursor-pointer rounded-md relative mb-3 ${
                  user === 'landlord' ? activeClass : inActiveClass
                }`}
                onClick={() => setUser('landlord')}
              >
                <img src={landlord} alt="" loading="lazy" />
                <h3 className="font-bold">Landlord</h3>
                <p>
                  Showcase your properties and connect with reliable tenants.
                </p>
                {user === 'landlord' ? (
                  <CgRadioChecked className="absolute top-3 right-3 text-[20px] text-yellow" />
                ) : (
                  <CgRadioCheck className="absolute top-3 right-3 text-[20px]" />
                )}
              </div>
              <div
                className={`md:w-6/12 p-3 cursor-pointer rounded-md relative mb-3 ${
                  user === 'tenant' ? activeClass : inActiveClass
                }`}
                onClick={() => setUser('tenant')}
              >
                <img src={tenant} alt="" loading="lazy" />
                <h3 className="font-bold">Tenant</h3>
                <p>
                  Connect directly with landlords, and schedule viewings
                  effortlessly
                </p>
                {user === 'tenant' ? (
                  <CgRadioChecked className="absolute top-3 right-3 text-[20px] text-yellow" />
                ) : (
                  <CgRadioCheck className="absolute top-3 right-3 text-[20px]" />
                )}
              </div>
            </div>
            {user !== '' && (
              <Fade>
                <Link
                  to={
                    user === 'landlord'
                      ? 'https://staging-app.rentaly.rent/landlord/auth/sign-up'
                      : 'https://staging-app.rentaly.rent/tenant/auth/sign-up'
                  }
                  className="px-4 justify-around flex mx-auto bg-blue text-white py-5 rounded-md cursor-pointer my-3"
                >
                  Create account as a {user}
                </Link>
              </Fade>
            )}
            <Link
              to={
                user === 'landlord'
                  ? 'https://staging-app.rentaly.rent/landlord/auth/sign-in'
                  : 'https://staging-app.rentaly.rent/tenant/auth/sign-in'
              }
              className="text-blue block text-center"
            >
              Already have an account? Sign in
            </Link>
          </div>
        </div>
      </Modal>

      <button
        className={`fixed md:bottom-10 md:right-10 bottom-5 right-5 rounded-full p-4 bg-blue text-base text-white shadow-md cursor-pointer z-20 blob ${
          showComponent ? '' : 'hidden'
        }`}
        onClick={() => setOpen(true)}
      >
        Create an account
      </button>
      <ScrollRestoration />
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {title} | Rentaly | Discovering and overseeing your ideal rental
          property hub.
        </title>
        <link rel="canonical" href="https://rentaly.com/" />
      </Helmet>
      <nav className="bg-blue md:px-8 px-4 py-4 flex justify-between items-center">
        <Link to="/">
          <LogoComponent />
        </Link>
        <ul className="md:flex gap-10 flex-row items-center hidden font-light ">
          <Link
            to="/"
            className={`text-white text-sm ${
              location?.pathname === '/' ? 'font-bold' : ''
            }`}
          >
            Home
          </Link>
          <Link
            to="/about"
            className={`text-white text-sm ${
              location?.pathname === '/about' ? 'font-bold' : ''
            }`}
          >
            About us
          </Link>
          <Link
            to="/listings"
            className={`text-white text-sm ${
              location?.pathname.includes('/listings') ? 'font-bold' : ''
            }`}
          >
            Property Listing
          </Link>
          <Link
            to="/properties"
            className={`text-white text-sm ${
              location?.pathname === '/properties' ? 'font-bold' : ''
            }`}
          >
            Property Portfolio
          </Link>
          <Link
            to="/faq"
            className={`text-white text-sm ${
              location?.pathname === '/faq' ? 'font-bold' : ''
            }`}
          >
            FAQ
          </Link>
          <button
            onClick={() => setOpen(true)}
            className="text-white text-sm py-2 px-4 rounded-md bg-yellow cursor-pointer"
          >
            Get Started
          </button>
        </ul>
        <button
          className="md:hidden block text-white text-sm"
          onClick={() => setMenu(!menu)}
        >
          {menu === false ? (
            <span className="flex gap-2 items-center">
              <IoMenu />
              Menu
            </span>
          ) : (
            <span className="flex gap-2 items-center">
              <IoMdClose />
              Close
            </span>
          )}
        </button>
      </nav>
      {menu && (
        <div className="bg-white p-4 w-full h-[100vh]" style={{ zIndex: 90 }}>
          <div className="">
            <ul className="font-light">
              <Link
                to="/"
                className={`text-gray block py-4 mb-3 text-center ${
                  location?.pathname === '/' ? 'font-bold text-black1' : ''
                }`}
              >
                Home
              </Link>
              <Link
                to="/about"
                className={`text-gray block py-4 mb-3 text-center ${
                  location?.pathname === '/about' ? 'font-bold text-black1' : ''
                }`}
              >
                About us
              </Link>
              <Link
                to="/listings"
                className={`text-gray text-sm block py-4 mb-3 text-center ${
                  location?.pathname.includes('/listings') ? 'font-bold' : ''
                }`}
              >
                Property Listing
              </Link>
              <Link
                to="/properties"
                className={`text-gray text-sm block py-4 mb-3 text-center ${
                  location?.pathname === '/properties' ? 'font-bold' : ''
                }`}
              >
                Property Portfolio
              </Link>
              <Link
                to="/faq"
                className={`text-gray block py-4 mb-3 text-center ${
                  location?.pathname === '/faq' ? 'font-bold text-black1' : ''
                }`}
              >
                FAQ
              </Link>
              <button
                onClick={() => {
                  setOpen(true);
                  setMenu(false);
                }}
                className="text-white text-sm py-2 px-4 rounded-md bg-yellow cursor-pointer flex mx-auto"
              >
                Get Started
              </button>
            </ul>
          </div>
        </div>
      )}
      {children}
      <footer className="footer pt-20 pb-20">
        <div className="container mx-auto md:px-0 px-5">
          <div>
            <Link to="/">
              <LogoComponent />
            </Link>
          </div>
          <div className="block md:flex md:flex-row mt-4">
            <div className="md:w-5/12 font-light">
              <h3 className="text-white mt-4">
                <b className="font-bold">Abuja address</b>
                <p>
                  Suite 405, Midel Centre,
                  <br />
                  Plot 14, off Oladipo Diya way,
                  <br />
                  Gudu District, Abuja.
                </p>
                <div className="mt-3 font-bold">Lagos address</div>
                <p>
                  42, Saka Tinubu, Victoria Island,
                  <br />
                  Insurers building,
                  <br />
                  3rd floor
                </p>
              </h3>
              <Link
                to="mailto:info@rentaly.rent"
                target="_blank"
                className={IconLinkStyle}
                style={{ marginTop: '20px' }}
              >
                <MdEmail />
                <span>info@rentaly.rent</span>
              </Link>
              <p className={IconLinkStyle}>
                <ImPhone />
                <span>+234 815 644 4343</span>
              </p>
            </div>
            <div className="md:w-3/12 md:mt-0 mt-10">
              <h3 className="text-white font-bold">IntoRealtors</h3>
              <div className="text-white mt-3 font-light">
                <Link to="/" className={NormalLinkStyle}>
                  Home
                </Link>
                <Link to="/about" className={NormalLinkStyle}>
                  About us
                </Link>
                <Link to="/faq" className={NormalLinkStyle}>
                  Frequently asked questions
                </Link>
                <Link to="/policy" className={NormalLinkStyle}>
                  Privacy Policy
                </Link>
                <Link to="/terms" className={NormalLinkStyle}>
                  Terms and Conditions
                </Link>
              </div>
            </div>
            <div className="md:w-2/12 text-white md:mt-0 mt-10">
              <h3 className="font-bold">Get Started</h3>
              <div className="text-white mt-3 font-light">
                <Link
                  to="https://staging-app.rentaly.rent"
                  className={NormalLinkStyle}
                >
                  Sign up
                </Link>
                <Link
                  to="https://staging-app.rentaly.rent/landlord/auth/sign-in"
                  className={NormalLinkStyle}
                >
                  Sign In
                </Link>
              </div>
            </div>
            <div className="md:w-2/12 text-white md:mt-0 mt-10">
              <h3 className=" font-bold">Follow us</h3>
              <div className="text-white mt-3 font-light">
                <Link
                  to="https://twitter.com"
                  target="_blank"
                  className={IconLinkStyle}
                >
                  <BsTwitter /> Twitter
                </Link>
                <Link
                  to="https://www.instagram.com/rentaly01/"
                  target="_blank"
                  className={IconLinkStyle}
                >
                  <BsInstagram />
                  <span>Instagram</span>
                </Link>
                <Link to="https://facebook.com" className={IconLinkStyle}>
                  <FaFacebookF />
                  <span>Facebook</span>
                </Link>
                <Link to="http://youtube.com" className={IconLinkStyle}>
                  <BsYoutube /> <span>YouTube</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default WebsiteLayout;
